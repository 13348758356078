.footer {
    a {
        color: #8e98a2;
        text-decoration: none;


        &:hover {
            color: #fff;
        }
    }

    .footerTitle {
        font-size: 1.225rem;
    }

    .footerCredits {
        border-top: 1px solid rgba(255, 255, 255, .1);
    }

    .footerSocial {

        ul {

            li {

                img {
                    max-width: 18px;
                }
            }
        }
    }
}

.toTop {
    position: fixed;
    width: 40px;
    aspect-ratio: 1/1;
    bottom: 80px;
    right: 80px;
    background-color: #333333;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;

    svg {
        width: 50%;
    }
}

.cta {

    .ctaImage {
        position: absolute;
        top: -40px;
        right: 120px;
        width: 280px;
    }

    .ctaContent {
        margin-top: 30px;

        .ctaTitle {
            font-size: 3rem
        }
    }

    a, button {
        background-color: #FFFFFF;
        color: #0e1d2c;
    }
}

@media (max-width: 991.98px) {
	.ctaTitle {
		font-size: 2.5rem;
	}
	.ctaImage {
		top: -27px;
		right: 20px;
		width: 200px;
	}
}

@media (max-width: 767.98px) {
	.ctaTitle {
		font-size: 2rem;
	}
	.ctaImage {
        display: none;
	}
    .footerTitle {
		font-size: 1.125rem;
	}

    .toTop {
        bottom: 40px !important;
        right: 40px !important;
    }
}

@media (max-width: 575.98px) {
	.ctaTitle {
		font-size: 1.5rem;
	}
    .footerLogo {
		max-width: 120px;
	}
}