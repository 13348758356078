.primaryNav {
    height: 80px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    // position: fixed;
    width: 100%;
    // top: 0;
    // left: 0;
    // z-index: 1000;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    .navbarBrand {
        display: flex;
        align-items: center;
        svg {
            width: 150px;
            height: 70px;
        }
    }

    .navbarNav {
        display: flex;
        align-items: center;
        list-style: none;
        transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
        max-height: 0;

        &.active {
            max-height: 500px;
            opacity: 1;
        }

        li {
            margin-left: 1.5rem;

            .navLink {
                color: #212529;
                font-weight: 500;
                padding: 0.5rem 1rem;
                border-radius: 4px;
                transition: background-color 0.3s ease, color 0.3s ease;

                &:hover, &.active {
                    background-color: #f0f0f08e;
                }
            }
        }
    }

    .menuIcon {
        width: 30px;
        height: 30px;
        cursor: pointer;
        display: none;
        transition: transform 0.3s ease;

        svg {
            width: 100%;
            height: 100%;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    @media (max-width: 992px) {
        .menuIcon {
            display: block;
        }

        .navbarNav {
            position: absolute;
            top: 80px;
            left: 0;
            right: 0;
            background-color: #fff;
            flex-direction: column;
            align-items: center;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            padding: 1rem 0;
			opacity: 0;
			overflow: hidden;
        }

        .navbarNav.active {
            max-height: 500px;
            opacity: 1;
        }

        .navbarNav li {
            margin: 1rem 0;

            .navLink {
                font-size: 1.2rem;
                padding: 0.5rem 2rem;
            }
        }
    }
}